import styled from 'styled-components';

import { Breakpoint } from '../../../constants';
import { ReactComponent as How } from '../../../images/graphics/how.svg';
import { ReactComponent as What } from '../../../images/graphics/what.svg';
import { ReactComponent as Why } from '../../../images/graphics/why.svg';

const StyledHighlight = styled('div')`
  width: 100%;
  max-width: var(--padded-container-width);
  padding: 0 var(--spacing);
  margin: 0 auto;

  h2 {
    margin: 1em 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  .section {
    min-height: 100px;
    width: 100%;
    display: flex;
  }

  .shapes {
    width: 20%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-grow: 0;
    margin: 0 var(--spacing);

    .shape {
      width: 100%;
    }
  }

  .text {
    width: 575px;
    margin-left: var(--spacing);

    .label {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 1em;
      letter-spacing: .1em;
      color: var(--lighter-blue)
    }

    .title {
      width: 75%;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 1em;
      letter-spacing: -.028em;
      line-height: 1.1;
    }

    .description {
      font-size: 24px;
      line-height: 1.5;
    }
  }

  .highlight {
    width: 85%;
    font-size: 76px;
    color: var(--text-blue);
    margin: 2em 0;
    line-height: 1.2;

    .altText {
      color: var(--white);
      font-weight: bold;
      font-family: Sectra, serif;
    }
  }

  @media (max-width: ${Breakpoint.LG}) {
    .highlight {
      font-size: 5.9375vw;
      margin: 11.875vw 0;
    }
  }

  @media (max-width: ${Breakpoint._960}) {
    .shapes {
      width: 40%;
      margin: 0;
    }
  }

  @media (max-width: ${Breakpoint.MD}) {
    .text {
      .label {
        // font-size: 16px;
      }

      .title {
        // font-size: 32px;
      }

      .description {
        // font-size: 20px;
      }
    }
  }

  @media (max-width: ${Breakpoint.SM}) {
    .content {
      gap: 60px;
    }

    .section {
      flex-direction: column;
    }

    .shapes {
      width: 150px;

      .shape {
        position: relative;
        height: 100%;

        &.what {
          left: -9%;
        }

        &.how {
          left: -16%;
        }

        &.why {
          left: -31%;
        }
      }
    }

    .text {
      width: auto;
      margin-left: 0;

      .label {
        // font-size: 12px;
      }

      .title {
        width: 100%;
        // font-size: 24px;
      }

      .description {
        // font-size: 16px;
      }
    }

    .highlight {
      font-size: 38px;
    }
  }

  @media (max-width: ${Breakpoint._460}) {
    .highlight {
      width: 100%;
    }
  }
`;

export const Highlight = () => (
  <StyledHighlight>
    <h2>Our unique advantage</h2>

    <div className="content">
      <div className="section">
        <div className="shapes">
          <What className="shape what" />
        </div>
        <div className="text">
          <div className="label">WHAT</div>
          <div className="title">
            We create connected medical devices
          </div>
          <div className="description">
            We focus on a specialized field of connected medical devices, we are vertically integrated, we’ve learned how to do it really well.
          </div>
        </div>
      </div>
      <div className="section">
        <div className="shapes">
          <How className="shape how" />
        </div>
        <div className="text">
          <div className="label">HOW</div>
          <div className="title">
            With like-minded partners
          </div>
          <div className="description">
            We partner with like-minded co-founders, investors, and talent to build companies that grow quickly and scale exponentially.
          </div>
        </div>
      </div>
      <div className="section">
        <div className="shapes">
          <Why className="shape why" />
        </div>
        <div className="text">
          <div className="label">WHY</div>
          <div className="title">
            To improve the lives of those around us
          </div>
          <div className="description">
            We improve medical device technology so that it can generate better outcomes, while decentralizing and revolutionizing the medical industry.
          </div>
        </div>
      </div>
    </div>

    <div className="highlight">
      <span>The health of humanity rests on our ability to continue to innovate, </span>
      <span className="altText">delivering the best possible health outcomes.</span>
    </div>
  </StyledHighlight>
);
