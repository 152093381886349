import { useState } from 'react';
import styled from 'styled-components';

import { Dialog, Link } from '../../../components';
import { Breakpoint } from '../../../constants';

import { ReactComponent as Logo } from '../../../images/logo.svg';
import { ReactComponent as Arrow } from '../../../images/graphics/arrow.svg';
import { ReactComponent as Instagram } from '../../../images/social/instagram.svg';
import { ReactComponent as Linkedin } from '../../../images/social/linkedin.svg';
import { ReactComponent as Twitter } from '../../../images/social/twitter.svg';

import { SignupForm } from '../forms';

const StyledFooter = styled('footer')`
  .newsletter {
    background: var(--text-blue);
    padding: 30px 0;

    .wrapper {
      max-width: var(--padded-container-width);
      padding: 0 var(--spacing);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .label {
      font-size: 40px;
      letter-spacing: -1px;

      .altText {
        font-family: Sectra, serif;
        font-weight: bold;
        letter-spacing: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .signup {
      width: 25%;
      font-size: 24px;
      padding: 10px 15px;
      color: var(--dark-blue);
      border: 2px solid;
      border-radius: 10em;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        position: relative;
        width: 2em;
        height: 1em;
        margin-left: 1em;
        left: 0;
        transition: left .1s linear;

        path {
          fill: var(--dark-blue);
        }
      }

      &:hover {
        .icon {
          left: .5em;
        }
      }
    }
  }

  .content {
    max-width: var(--padded-container-width);
    padding: 0 var(--spacing);
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 40px;
    width: auto;
  }

  .leftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .socials {
    display: flex;
    gap: 7px;
    margin: 40px 0;
  }

  .social {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .addresses {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 40px;

    font-size: 14px;

    .location {
      font-size: 20px;
      margin-bottom: .5em;
    }
  }

  .copyright {
    max-width: var(--padded-container-width);
    padding: 0 var(--spacing);
    margin: 0 auto var(--spacing);
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }


  //mobile first
  @media (min-width: ${Breakpoint.SM}) {
    .addresses {
      flex-direction: row;
      align-items: start;
      text-align: left;
    }

    .leftSide {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: var(--spacing);
    }
  }


  // mobile first
  @media (min-width: ${Breakpoint._960}) {
    .content {
      flex-direction: row;
      align-items: start;
      margin: 90px auto;
    }

    .addresses {
      gap: 40px;
      width: auto;
    }

    .leftSide {
      align-items: start;
      flex-direction: column;
      width: auto;
      margin-bottom: 0;
    }

    .copyright {
      flex-direction: row;
    }
  }

  @media (max-width: ${Breakpoint.LG}) {
    .newsletter {
      padding: 2.34375vw 0;

      .label {
        font-size: 3.125vw;
      }

      .signup {
        font-size: 1.875vw;
      }
    }
  }

  @media (max-width: ${Breakpoint.MD}) {
    .newsletter {
      padding: var(--spacing) 0;

      .wrapper {
        flex-direction: column;
      }

      .label {
        font-size: 32px;
        margin-bottom: .5em;
        text-align: center;
      }

      .signup {
        width: auto;
        font-size: 14px;

        .icon {
          margin-left: 3em;
        }
      }
    }
  }

  @media (max-width: ${Breakpoint._460}) {
    .newsletter {
      .label {
        // font-size: 18px;
      }
    }
  }
`;

export const Footer = () => {
  const [openForm, setOpenForm] = useState(false);
  const onFormClose = () => setOpenForm(false);

  return (
    <StyledFooter>
      <div className="newsletter">
        <div className="wrapper">
          <div className="label">
            <button className="altText" onClick={() => setOpenForm(true)}>Subscribe for</button>
            <span> updates, news, and more!</span>
          </div>

          <button className="signup" onClick={() => setOpenForm(true)}>
            <div>Sign Up</div>
            <Arrow className="icon" />
          </button>
        </div>
      </div>

      <div className="content">
        <div className="leftSide">
          <Logo className="logo" />

          <div className="socials">
            <Link href="https://www.instagram.com/10xbeta/">
              <Instagram className="social" />
            </Link>
            <Link href="https://twitter.com/10XBeta">
              <Twitter className="social" />
            </Link>
            <Link href="https://www.linkedin.com/company/10xbeta">
              <Linkedin className="social" />
            </Link>
          </div>
        </div>

        <div className="addresses">
          <div className="address">
            <div className="location">NEW YORK</div>
            <div>
              <span>19 Morris Ave, </span>
              <Link href="https://newlab.com">New Lab</Link>
              <span>,</span>
            </div>
            <div>Building 128</div>
            <div>Brooklyn, NY 11205</div>
            <div><a href="mailto:brooklyn@10xbeta.com">brooklyn@10xbeta.com</a></div>
          </div>

          <div className="address">
            <div className="location">SOUTH AFRICA</div>
            <div>Parkwood</div>
            <div>Johannesburg, 2193</div>
            <div><a href="mailto:southafrica@10xbeta.com">southafrica@10xbeta.com</a></div>
          </div>

          <div className="address">
            <div className="location">AMSTERDAM</div>
            <div>LTS Doctor Jan van</div>
            <div>Breemenstraat 1, 1057 BW</div>
            <div>Amsterdam, Netherlands</div>
            <div><a href="mailto:amsterdam@10xbeta.com">amsterdam@10xbeta.com</a></div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div>© 2023 10XBETA LLC, All Rights Reserved.</div>
{/*        <div>
          <Link href="#">Privacy</Link>
          <span> | </span>
          <Link href="#">Terms & Conditions</Link>
        </div>*/}
      </div>

      <Dialog open={openForm} onClose={onFormClose}>
        <SignupForm onClose={onFormClose} />
      </Dialog>
    </StyledFooter>
  );
};
