import { useEffect, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '../constants';

interface DialogProps extends PropsWithChildren {
  open: boolean;
  full?: boolean;
  onClose: () => void;
}

const StyledDialog = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .8);
  transition: opacity .25s;
  opacity: 0;
  z-index: -1;

  &.open {
    opacity: 1;
    z-index: 10;

    .content-wrapper {
      right: 0;
    }
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    right: -100%;
    height: 100%;
    max-width: 100%;
    transition: right .5s;

    &.full {
      width: 100%;
      max-width: ${Breakpoint.CONTAINER_MAX};
    }
  }
`;

export const Dialog = ({ open, onClose, children, full }: DialogProps) => {
  useEffect(() => {
    const handleKeydown = ({ keyCode }: any) => keyCode === 27 && onClose();

    if (open) {
      window.addEventListener('keydown', handleKeydown);
    } else {
      window.removeEventListener('keydown', handleKeydown)
    }

    return () => window.removeEventListener('keydown', handleKeydown);
  }, [open, onClose]);

  return (
    <StyledDialog className={`${open && 'open'}`} onClick={onClose}>
      <div className={`content-wrapper ${full && 'full'}`} onClick={e => e.stopPropagation()}>
        { children }
      </div>
    </StyledDialog>
  );
};
