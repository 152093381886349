export enum SendState {
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'FAILED',
}

export const useSendGrid = () => {
  return (data: any) => {
    return fetch(process.env.REACT_APP_SENDGRID_URL!, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(body => {
      if (!body.ok) {
        throw new Error();
      }

      return body;
    });
  };
}
