import { useEffect, useRef, useState, RefObject } from 'react';
import styled from 'styled-components';

import { Breakpoint } from '../../../constants';

const StyledSpotlight = styled('div')`
  --pad: calc(.5 * var(--spacing));

  h2 {
    width: 100%;
    max-width: var(--padded-container-width);
    padding: 0 var(--spacing);
    margin: 0 auto .75em;
  }
  
  .carousel {
    width: 100%;
    max-width: var(--padded-container-width);
    margin: 0 auto;
  }

  .container {
    margin: 0 auto;
  }

  .wrapper {
    position: relative;
    display: flex;
    padding-top: 37.25%;
  }

  .slides {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    gap: var(--spacing);

    scroll-snap-type: x mandatory;
    ::-webkit-scrollbar { display: none; }
  }

  .slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background-position: right;
    background-size: cover;
    padding: var(--pad);
    overflow: hidden;

    scroll-snap-align: start;
    scroll-snap-stop: always;

    .title {
      font-size: 65px;
      font-weight: bold;
      margin-bottom: .5em;
      line-height: 1;
    }

    .description {
      font-size: 22px;
      max-width: 15em;
    }

    &[data-id=validose] {
      background-image: url(/images/validose.png);
    }

    &[data-id=everbeat] {
      background-image: url(/images/everbeat.png);
    }

    &[data-id=cryo] {
      background-image: url(/images/cryo.png);
    }
  }

  .pagination {
    position: absolute;
    bottom: var(--pad);
    left: var(--pad);
    right: var(--pad);
    display: flex;

    .page {
      padding: 10px;
      opacity: .5;
      transition: opacity .2s linear;

      &::after {
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: var(--white);
      }

      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }

  // mobile first
  @media (min-width: ${Breakpoint._460}) {
    --pad: var(--spacing);
  }

  // mobile first
  @media (min-width: ${Breakpoint.SM}) {
    --pad: calc(2 * var(--spacing));

    .pagination {
      bottom: var(--spacing);
    }
  }

  // mobile first
  @media (min-width: ${Breakpoint.MD}) {
    .slides,
    .slide {
      border-radius: 8px;
    }

    .container {
      padding: 0 var(--spacing);
    }
  }

  @media (max-width: ${Breakpoint.LG}) {
    .slide {
      .title {
        font-size: 5.078vw;
      }

      .description {
        font-size: 1.72vw;
      }
    }
  }

  @media (max-width: ${Breakpoint._960}) {
    .wrapper {
      padding-top: 60%;
    }

    .slide {
      background-position: 50%;

      .title {
        font-size: 9vw;
      }

      .description {
        font-size: 3vw;
      }
    }
  }

  @media (max-width: ${Breakpoint._460}) {
    .slide {
      .title {
        font-size: 40px;
      }

      .description {
        font-size: 14px;
      }
    }
  }
`;

const ACTIVE = 'active';

const AUTO_SCROLL = 5000;

const SLIDES = [
  {
    title: 'Validose',
    description: 'Secure delivery of high-risk medication with connected prescription technology.',
    className: 'validose',
  },
  {
    title: 'Everbeat',
    description: 'Unleashing the rhythm of your heart, to improve and optimize your health.',
    className: 'everbeat',
  },
  {
    title: 'Cryo Medica',
    description: 'Harnessing the science of cryoablation for cancer treatment surgeries.',
    className: 'cryo',
  },
];

const getSlides = (slides: HTMLElement): HTMLDivElement[] =>
  Array.from(slides.querySelectorAll('.slide'));

const getSlide = (slides: HTMLElement, className: string): HTMLDivElement =>
  slides.querySelector(`[data-id=${className}]`)!;

export const Spotlight = () => {
  const slidesRef: RefObject<HTMLDivElement> = useRef(null);

  // @TODO: remove autoScroll
  const [autoScroll, setAutoScroll] = useState(false);
  const [activeSlide, setActiveSlide] = useState({
    [SLIDES[0].className]: true
  });

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: slidesRef.current,
      rootMargin: '0px',
      threshold: [0, .33, .66, 1],
    });

    const slides = getSlides(slidesRef.current!);

    slides.forEach(slide => observer.observe(slide));
    return () => slides.forEach(slide => observer.unobserve(slide));
  }, [slidesRef]);

  useEffect(() => {
    if (!autoScroll) {
      return;
    }

    const interval = setInterval(() => {
      if (autoScroll) {
        const slides = getSlides(slidesRef.current!);
        const currentIndex = slides.findIndex(slide => slide.classList.contains(ACTIVE));
        const nextIndex = currentIndex < slides.length - 1 ? currentIndex + 1 : 0

        scrollTo(SLIDES[nextIndex].className);
      } else {
        clearInterval(interval);
      }
    }, AUTO_SCROLL);

    return () => clearInterval(interval);
  }, [autoScroll, slidesRef]);

  const handleIntersection = ([entry]: any[]) => {
    const isActive = entry.intersectionRatio > .33;

    setActiveSlide(s => ({
      // maintain state if current is inActive
      ...(!isActive ? s : {}),

      [entry.target.dataset.id]: isActive
    }));
  };

  const handleScrollTo = (className: string) => {
    scrollTo(className);
    setAutoScroll(false);
  }

  const scrollTo = (className: string) => {
    const slide: HTMLDivElement = getSlide(slidesRef.current!, className);
    slidesRef.current!.scrollTo({ left: slide.offsetLeft, behavior: 'smooth' });
  }

  return (
    <StyledSpotlight>
      <h2>Company spotlight</h2>

      <div className="carousel">
        <div className="container">
          <div className="wrapper">
            <div className="slides overflow-scroll" ref={slidesRef}>
              {
                SLIDES.map(({ className, title, description }) => (
                  <div className={`slide ${activeSlide[className] && ACTIVE}`} key={className} data-id={className}>
                    <div className="title">{ title }</div>
                    <div className="description">{ description }</div>
                  </div>
                ))
              }
            </div>

            <div className="pagination">
              {
                SLIDES.map(({ className }) => (
                  <button
                    onClick={() => handleScrollTo(className)}
                    className={`page ${activeSlide[className] && ACTIVE}`}
                    key={className}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </StyledSpotlight>
  );
};
