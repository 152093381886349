import styled from 'styled-components';

import { Breakpoint } from '../../../constants';
import { ReactComponent as Logo } from '../../../images/logo.svg';

const StyledHeader = styled('header')`
  width: 100%;
  max-width: var(--padded-container-width);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing);

  .logo {
    height: 40px;
    width: auto;
  }

  button {
    font-size: 20px;
    padding: 15px 35px 15px 25px;
    border: 1px solid;
    border-radius: 10em;
  }

  @media (max-width: ${Breakpoint.MD}) {
    button {
      display: none;
    }
  }

  @media (max-width: ${Breakpoint.SM}) {
    .logo {
      height: 20px;
    }
  }
`;

export const Header = () => (
  <StyledHeader>
    <Logo className="logo" />
    {/*<button>Contact Us</button>*/}
  </StyledHeader>
);
