import styled from 'styled-components';

import { ReactComponent as Close } from '../../../images/graphics/close.svg';

interface SignupFormProps {
  onClose: () => void;
}

const StyledSignupForm = styled('div')`
  width: 640px;
  max-width: 100%;
  height: 100%;
  background: var(--text-blue);
  padding: var(--spacing);
  display: flex;
  flex-direction: column;

  .close {
    svg {
      width: 20px;

      line {
        stroke: var(--dark-blue);
      }
    }
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export const SignupForm = ({ onClose }: SignupFormProps) => (
  <StyledSignupForm>
    <div className="header">
      <button className="close" onClick={onClose}>
        <Close />
      </button>
    </div>

    <iframe title="signup" src={process.env.REACT_APP_SENDGRID_SIGNUP} />
  </StyledSignupForm>
);
