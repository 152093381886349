import { StrictMode } from 'react';
import styled from 'styled-components';

import { Router } from './Router';

const StyledApp = styled('div')(() => `
  height: 100%;
  display: flex;
  flex-direction: column;
`);

export const App = () => (
  <StrictMode>
    <StyledApp>
      <Router />
    </StyledApp>
  </StrictMode>
);
