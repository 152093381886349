import styled from 'styled-components';

import { Breakpoint } from '../../../constants';
import { ReactComponent as Arrow } from '../../../images/graphics/arrow.svg';

import { CONTACT_FORM_TYPE } from '../forms';

const StyledContact = styled('div')`
  width: 100%;
  max-width: var(--padded-container-width);
  padding: 0 var(--spacing);
  margin: 0 auto;

  .actions {
    display: flex;
    align-items: stretch;
    gap: 30px;

    margin: calc(var(--spacing) * 3) 0;
  }

  .action {
    width: 34%;

    button {
      text-align: left;
      width: 100%;
    }

    h3 {
      max-width: 4.8em;
      font-family: Walsheim, sans-serif;
    }

    &:hover,
    &:focus {
      .text {
        .icon {
          transform: translateX(50%);
        }
      }
    }

    &.invest {
      .text {
        color: var(--invest);

        svg path {
          fill: var(--invest);
        }
      }
    }

    &.cofound {
      .text {
        color: var(--cofound);

        svg path {
          fill: var(--cofound);
        }
      }
    }

    &.collaborate {
      .text {
        color: var(--collaborate);

        svg path {
          fill: var(--collaborate);
        }
      }
    }
  }

  .text {
    border-top: 1px solid var(--white);
    margin-top: 20px;
    padding-top: 20px;
    font-size: 24px;
    display: flex;
    align-items: center;

    .icon {
      width: auto;
      height: 1em;
      margin-left: .5em;
      transition: transform .1s linear;
    }
  }

  @media (max-width: ${Breakpoint.LG}) {
    .text {
      font-size: 1.875vw;
    }
  }

  @media (max-width: ${Breakpoint.SM}) {
    h2 {
      margin-bottom: 2em;
    }

    .actions {
      flex-direction: column;
      margin: var(--spacing) 0;
      gap: calc(3 * var(--spacing))
    }

    .action {
      width: 100%;

      &:hover,
      &:focus {
        .text {
          .icon {
            transform: none;
          }
        }
      }

      h3 {
        max-width: 100%;
        font-size: 36px;
      }
    }

    .text {
      margin-top: .5em;
      padding-top: .5em;
      font-size: 24px;
      justify-content: space-between;
    }
  }
`;

interface ContactProps {
  onFormOpen: (action: CONTACT_FORM_TYPE) => void;
}

export const Contact = ({ onFormOpen }: ContactProps) => (
  <StyledContact>
    <h2>
      <div>Let’s do great</div>
      <div>things together.</div>
    </h2>

    <div className="actions">
      <div className="action invest">
        <button onClick={() => onFormOpen(CONTACT_FORM_TYPE.INVEST)}>
          <h3>Invest With Us</h3>
          <div className="text">
            <div>See Current Opportunities</div>
            <Arrow className="icon" />
          </div>
        </button>
      </div>
      <div className="action cofound">
        <button onClick={() => onFormOpen(CONTACT_FORM_TYPE.COFOUND)}>
          <h3>Co-Found With Us</h3>
          <div className="text">
            <div>Pitch Your Product</div>
            <Arrow className="icon" />
          </div>
        </button>
      </div>
      <div className="action collaborate">
        <button onClick={() => onFormOpen(CONTACT_FORM_TYPE.COLLABORATE)}>
          <h3>Collaborate With Us</h3>
          <div className="text">
            <div>Tell Us More About You</div>
            <Arrow className="icon" />
          </div>
        </button>
      </div>
    </div>
  </StyledContact>
);
