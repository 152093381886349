import { useState } from 'react';
import styled from 'styled-components';

import { Dialog } from '../../components';
import { Breakpoint } from '../../constants';

import { ContactForm, CONTACT_FORM_TYPE } from './forms';

import {
  Header,
  Landing,
  Highlight,
  Spotlight,
  Contact,
  Footer,
} from './sections';

const StyledHome = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--dark-blue);
  color: var(--white);

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    font-family: 'Sectra', serif;
    font-weight: bold;
  }

  h1,
  .h1 {
    font-size: 115px;
    line-height: 1;
  }

  h2,
  .h2 {
    font-size: 90px;
  }

  h3,
  .h3 {
    font-size: 40px;
  }

  .header-section {
    position: relative;
    margin-bottom: calc(-1 * var(--spacing) - 1em);
    z-index: 1;
  }

  .landing-section {
    position: relative;
    padding-top: var(--spacing);
    margin-bottom: -240px;

    .landing-wrapper {
      position: relative;
      z-index: 2;
    }

    .video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      overflow: hidden;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
      }

      .top-mask,
      .bottom-mask {
        position: absolute;
        left: 0;
        width: 100%;
      }

      .top-mask {
        top: 0;
        height: 15%;
        background: linear-gradient(var(--dark-blue), transparent);
      }

      .bottom-mask {
        bottom: 0;
        height: 20%;
        background: linear-gradient(transparent, var(--dark-blue));
      }
    }
  }

  .highlight-section {
    position: relative;
    padding-top: 240px;
    margin-bottom: -450px;
    background: linear-gradient(
      transparent,
      var(--text-blue-50) 50%,
      transparent
    );
    z-index: 1;
  }

  .spotlight-wrapper {
    position: relative;
    padding-top: 450px;

    .spotlight-background {
      max-height: 100%;
      overflow: hidden;

      &,
      .spotlight-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      
      img {
        width: 100%;
      }

      .spotlight-mask {
        height: 100%;
        background: linear-gradient(
          var(--dark-blue),
          transparent,
          var(--dark-blue)
        );
      }
    }
  }

  .spotlight-section {
    position: relative;
  }

  .contact-section {
    position: relative;
    padding-top: 225px;
  }

  .footer-section {
    position: relative;
    margin-top: calc(2 * var(--spacing));
  }

  // mobile-first
  @media (min-width: ${Breakpoint.MD}) {
    .landing-section {
      .video-wrapper {
        height: 100%;
      }
    }
  }

  @media (max-width: ${Breakpoint.LG}) {
    h1,
    .h1 {
      font-size: 9vw;
    }

    h2,
    .h2 {
      font-size: 7.03vw;
    }

    h3,
    .h3 {
      font-size: 3.125vw;
    }

    .highlight-section {
      margin-bottom: -35vw;
    }

    .spotlight-wrapper {
      padding-top: 35vw;
    }

    .contact-section {
      padding-top: 17.5vw;
    }

    .footer-section {
      margin-top: 0;
    }
  }

  @media (min-width: ${Breakpoint.MAX_VIDEO_SIZE}) {
    .landing-section {
      .video-wrapper {
        video {
          height: auto;
          min-height: 100%;
          min-width: 100%;
        }
      }
    }
  }

  @media (max-width: ${Breakpoint.MD}) {
    h1,
    .h1 {
      font-size: 10.5vw;
    }
  }

  @media (max-width: ${Breakpoint.SM}) {
    h2,
    .h2 {
      font-size: 45px;
    }
  }
`;

export const Home = () => {
  const [formType, setFormType] = useState<CONTACT_FORM_TYPE>();
  const [openForm, setOpenForm] = useState(false);

  const onFormClose = () => setOpenForm(false);

  const onFormOpen = (action: CONTACT_FORM_TYPE) => {
    setFormType(action);
    setOpenForm(true);
  }


  return (
    <StyledHome className='overflow-scroll'>
      <div className="header-section">
        <Header />
      </div>

      <div className="landing-section">
        <div className="landing-wrapper">
          <Landing onFormOpen={onFormOpen} />
        </div>

        <div className="video-wrapper">
          <video id="background-video" autoPlay loop muted poster="/video/hero.jpg">
            <source src="/video/hero-c.mp4" type="video/mp4" />
          </video>

          <div className="top-mask" />
          <div className="bottom-mask" />
        </div>
      </div>

      <div className="highlight-section">
        <Highlight />
      </div>

      <div className="spotlight-wrapper">
        <div className="spotlight-background">
          <img src="/video/still-faded.jpg" alt="background" />

          <div className="spotlight-mask" />
        </div>

        <div className="spotlight-section">
          <Spotlight />
        </div>

        <div className="contact-section">
          <Contact onFormOpen={onFormOpen} />
        </div>
      </div>

      <div className="footer-section">
        <Footer />
      </div>

      <Dialog open={openForm} onClose={onFormClose} full={true}>
        <ContactForm open={openForm} type={formType} onClose={onFormClose} />
      </Dialog>
    </StyledHome>
  );
};
