import styled from 'styled-components';

import { Breakpoint } from '../../../constants';
import { ReactComponent as Arrow } from '../../../images/graphics/arrow.svg';
import { ReactComponent as Invest } from '../../../images/graphics/invest.svg';
import { ReactComponent as Cofound } from '../../../images/graphics/cofound.svg';
import { ReactComponent as Collaborate } from '../../../images/graphics/collaborate.svg';

import { CONTACT_FORM_TYPE } from '../forms';

const StyledLanding = styled('div')`
  width: 100%;
  max-width: var(--padded-container-width);
  padding: 0 var(--spacing) var(--spacing);
  margin: 0 auto;

  h1 {
    margin: .85em 0 0.35em;

    .subtext {
      font-size: .95em;
      letter-spacing: -.01em;
      font-family: 'Walsheim', sans-serif;
      font-weight: normal;
    }
  }

  .description {
    font-size: 30px;
    line-height: 1.4;
    max-width: 55%;
    margin-bottom: 2.75em;
  }

  .actions {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing);
    z-index: 1;
    margin: 0 calc(-1 * var(--spacing));
    background: linear-gradient(
      var(--text-blue-50),
      var(--dark-blue),
      var(--text-blue-50)
    );
  }

  .action {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--spacing);
    flex-shrink: 0;
    text-align: left;
    transition: box-shadow .1s linear;

    .text {
      color: var(--white);
    }

    .label {
      display: flex;
      align-items: center;

      svg {
        height: 1em;
        width: auto;
        margin-left: 1em;
      }
    }


    &.invest {
      background: var(--invest-bg);
      color: var(--invest);

      .title,
      .label {
        svg path {
          fill: var(--invest);
        }
      }
    }

    &.cofound {
      background: var(--cofound-bg);
      color: var(--cofound);

      .title,
      .label {
        svg path {
          fill: var(--cofound);
        }
      }
    }

    &.collaborate {
      background: var(--collaborate-bg);
      color: var(--collaborate);

      .title,
      .label {
        svg path {
          fill: var(--collaborate);
        }
      }
    }
  }

  .title {
    position: relative;
    font-size: 16.75vw;
    font-weight: bold;
    font-family: Sectra, serif;
    display: flex;
    align-items: end;
    flex-shrink: 0;
    margin-bottom: .2em;
    width: 100%;

    .graphic {
      position: absolute;
      bottom: 1.5em;
      left: 0;
      width: 2.8em;
      height: auto;
      display: none;
    }
  }

  .wrapper {
    height: 100%;
    width: 100%;
    font-size: 4.1vw;
    line-height: 1.4;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
  }

  .text {
    padding-right: var(--spacing);
    width: 85%;
  }

  .label {
    font-size: 1.5em;
    font-weight: bold;
    flex-shrink: 0;

    span {
      display: none;
    }

    svg {
      transition: transform .1s linear;
    }
  }

  // mobile-first
  @media (min-width: ${Breakpoint.MD}) {
    .description {
      margin-bottom: 4.75em;
    }

    .actions {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
      margin: 0;
      background: none;
    }

    .action {
      width: 32%;
      border-radius: 8px;
      padding: 3.125vw;

      &:hover,
      &:focus {
        box-shadow: .8vw .8vw .8vw rgba(0, 0, 0, 0.25);

        .label {
          svg {
            transform: translateX(50%);
          }
        }
      }
    }

    .title {
      padding-top: 50%;
      margin-bottom: 20px;
      font-size: 5.1vw;

      .graphic {
        display: block;
      }
    }

    .wrapper {
      flex-direction: column;
      align-items: start;
      font-size: 1.5625vw;
    }

    .text {
      padding-right: 0;
    }

    .label {
      position: relative;
      top: 0;
      right: 0;
      margin-top: 1em;
      font-size: 1.875vw;

      span {
        display: inline;
      }
    }
  }

  // mobile-first
  @media (min-width: ${Breakpoint.LG}) {
    .action {
      padding: var(--spacing);

      &:hover,
      &:focus {
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
      }
    }

    .title {
      font-size: 65px;
    }

    .wrapper {
      font-size: 20px;
    }

    .label {
      font-size: 1.2em;
    }
  }

  @media (max-width: ${Breakpoint.LG}) {
    h1 {
      font-size: 9vw;
    }

    .description {
      font-size: 2.35vw;
    }
  }

  @media (max-width: ${Breakpoint.MD}) {
    h1 {
      margin-top: 1em;
    }

    .description {
      font-size: 2.75vw;
      max-width: 73%;
    }
  }

  @media (max-width: ${Breakpoint.SM}) {
    h1 {
      margin-bottom: .7em;
    }

    .description {
      font-size: 16px;
      max-width: 85%;
    }
  }
`;

interface LandingProps {
  onFormOpen: (action: CONTACT_FORM_TYPE) => void;
}

export const Landing = ({ onFormOpen }: LandingProps) => (
  <StyledLanding>
    <h1>
      <div>Reinventing the</div>
      <div className="subtext">future of healthtech.</div>
    </h1>

    <div className="description">
      We invent, launch, and scale ground-breaking medical technology, working with the best and brightest minds in the industry.
    </div>

    <div className="actions">
      <button className="action invest" onClick={() => onFormOpen(CONTACT_FORM_TYPE.INVEST)}>
        <div className="title">
          <Invest className="graphic" />
          <div>invest.</div>
        </div>
        <div className="wrapper">
          <div className="text">
            For investors looking to back  the companies that are changing the world.
          </div>
          <div className="label">
            <span>Learn more</span>
            <Arrow />
          </div>
        </div>
      </button>
      <button className="action cofound" onClick={() => onFormOpen(CONTACT_FORM_TYPE.COFOUND)}>
        <div className="title">
          <Cofound className="graphic" />
          <div>co-found.</div>
        </div>
        <div className="wrapper">
          <div className="text">
            For startups, looking to turn  cutting-edge ideas into ground-breaking companies.
          </div>
          <div className="label">
            <span>Learn more</span>
            <Arrow />
          </div>
        </div>
      </button>
      <button className="action collaborate" onClick={() => onFormOpen(CONTACT_FORM_TYPE.COLLABORATE)}>
        <div className="title">
          <Collaborate className="graphic" />
          <div>collaborate.</div>
        </div>
        <div className="wrapper">
          <div className="text">
            For makers looking to join a team of like-minded designers, engineers, and developers.
          </div>
          <div className="label">
            <span>Learn more</span>
            <Arrow />
          </div>
        </div>
      </button>
    </div>
  </StyledLanding>
);
