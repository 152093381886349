import { Navigate, Route, Routes } from 'react-router-dom';

import { Home } from '../containers';

export const Router = () => (
  <Routes>
    <Route path="/" element={<Home />} />

    <Route path="*" element={<Navigate to="/" replace={true} />} />
  </Routes>
);
