export enum Breakpoint {
  XL = '1400px',
  LG = '1280px',
  MD = '768px',
  SM = '640px',
  XS = '320px',

  CONTAINER_MAX = '1200px',
  HEADER_MAX    = '1400px',

  MAX_VIDEO_SIZE = '1999px',

  _460 = '460px',
  _960 = '960px',
}

export enum AspectRatio {
  MD = '(max-aspect-ratio: 5/4)',
}