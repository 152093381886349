import { useEffect, useMemo, useRef, useState, RefObject } from 'react';
import styled from 'styled-components';

import { Link } from '../../../components';
import { Breakpoint } from '../../../constants';
import { useSendGrid, SendState } from '../../../hooks';
import { convertFileToBase64 } from '../../../utils';
import { ReactComponent as Attachment } from '../../../images/graphics/attachment.svg';
import { ReactComponent as Close } from '../../../images/graphics/close.svg';
import { ReactComponent as Error } from '../../../images/graphics/error.svg';
import { ReactComponent as Instagram } from '../../../images/social/instagram.svg';
import { ReactComponent as Linkedin } from '../../../images/social/linkedin.svg';
import { ReactComponent as Twitter } from '../../../images/social/twitter.svg';

const BYTE = 1024;
const MAX_FILE_SIZE = BYTE * 1000 * 4;

export enum CONTACT_FORM_TYPE {
  INVEST = 'INVEST',
  COFOUND = 'COFOUND',
  COLLABORATE = 'COLLABORATE',
}

enum FormValidation {
  MAX_SHORT = 40,
  MAX_LONG = 1000,
};

interface ContactFormProps {
  type?: CONTACT_FORM_TYPE;
  open: boolean;
  onClose: () => void;
}

const StyledContactForm = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .header {
    display: flex;
    margin: var(--spacing) 0;
    padding: 0 20px;
    align-items: center;
  }

  .close {
    margin-right: 20px;

    svg {
      width: 40px;
    }
  }


  &.invest {
    background: linear-gradient(var(--dark-blue), var(--invest));

    .close {
      svg {
        line {
          stroke: var(--invest);
        }
      }
    }

    .h2 {
      color: var(--invest);
    }
  }

  &.cofound {
    background: linear-gradient(var(--dark-blue), var(--cofound));

    .close {
      svg {
        line {
          stroke: var(--cofound);
        }
      }
    }

    .h2 {
      color: var(--cofound);
    }
  }

  &.collaborate {
    background: linear-gradient(var(--dark-blue), var(--collaborate));

    .close {
      svg {
        line {
          stroke: var(--collaborate);
        }
      }
    }

    .h2 {
      color: var(--collaborate);
    }
  }

  .wrapper {
    height: 100%;
    padding: var(--spacing) var(--spacing) 0 90px;

    .h2 {
      line-height: 1.2;
    }

    .sent-title {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 2;
      padding: var(--spacing) 0;

      .h2 {
        transform: translateY(-50%);
      }
    }

    .sent-content {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: .5em;
      text-align: center;
    }
  }

  .content {
    display: flex;
    gap: var(--spacing);
    padding-bottom: var(--spacing);
  }

  .counter {
    font-size: .4em;
    text-align: right;
    flex-shrink: 0;
  }

  .text {
    font-size: 20px;
  }

  .form {
    width: 40%;
    flex-shrink: 0;
    font-size: 30px;
    
    form {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 15px;

      input,
      textarea,
      .selector {
        width: 100%;
        outline: 0;
        color: inherit;
        border: 3px solid;
        font-family: inherit;
        font-size: inherit;
        background: transparent;
        padding: 15px;

        &::placeholder {
          color: inherit;
        }

        &[type="file"] {
          display: none;
        }
      }

      .message {
        width: 100%;
        position: relative;

        .counter {
          position: absolute;
          bottom: 15px;
          right: 15px;
        }
      }

      textarea {
        height: 200px;
        max-width: 100%;
        min-width: 100%;
        padding-bottom: 30px;
      }

      .file-upload {
        width: 100%;

        .selector {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &.icon {
            padding-right: 2.25em;
          }
        }

        .label {
          padding-right: 15px;
        }

        .counter {
          position: absolute;
          right: .5em;
          top: 50%;
          transform: translateY(-50%);

          display: flex;
          flex-direction: column;
          align-items: center;
          gap: .5em;

          svg {
            width: 3em;
          }
        }

        &.error {
          .selector {
            border-style: dashed;
          }
        }
      }

      .remove-attachment {
        margin-right: .5em;

        svg {
          height: .5em;
          width: .5em;

          line {
            stroke: var(--white);
          }
        }
      }

      .submit {
        width: 50%;
        padding: 15px;
        border: 3px solid;
        transition: border-radius .15s linear;

        &[disabled] {
          opacity: .75;
          cursor: initial;
        }

        &:hover {
          &:not([disabled]) {
            transition: border-radius .25s linear;
            border-radius: 5em;
          }
        }
      }

      .error {
        color: var(--yellow);

        .remove-attachment svg line {
          stroke: var(--yellow);
        }
      }
    }
  }

  .socials {
    display: flex;
    gap: 7px;
  }

  a {
    height: 30px;
    width: 30px;
    border-radius: 50%;

    svg {
      height: 100%;
      width: 100%;
    }
  }


  @media (max-width: ${Breakpoint.LG}) {
    .form {
      font-size: 2.5vw;

      form {
        input,
        textarea,
        .selector
        .submit, {
          padding: 1.25vw;
        }

        textarea {
          padding-bottom: 3.5vw;
        }

        .file-upload {
          .label {
            padding-right: 1.25vw;
          }

        }
      }
    }

  }

  @media (max-width: ${Breakpoint.MD}) {
    .wrapper {
      .sent-content {
        flex-direction: column;
        gap: 1em;
      }
    }
  }

  @media (max-width: ${Breakpoint.SM}) {
    .close {
      svg {
        width: 20px;
      }
    }

    .header {
      margin: calc(.25 * var(--spacing)) 0;
    }

    .wrapper {
      padding-left: 65px;
    }

    .content {
      flex-direction: column;
    }

    .form {
      width: 100%;
      margin-top: 1em;
      font-size: 18px;

      form {
        .submit,
        .file-upload {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: ${Breakpoint._460}) {
    .wrapper {
      .h2 {
        font-size: 9.8vw;
      }
    }
  }
`;

export const ContactForm = ({ open, type, onClose }: ContactFormProps) => {
  const sendGrid = useSendGrid();
  const fileInputRef: RefObject<HTMLInputElement> = useRef(null);
  const scrollRef: RefObject<HTMLDivElement> = useRef(null);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File>();

  const [sendState, setSendState] = useState<SendState>();

  useEffect(() => {
    if (!open) {
      scrollRef.current!.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [open, scrollRef]);

  const label = useMemo(() => {
    switch (type) {
      case CONTACT_FORM_TYPE.INVEST: {
        return 'Invest with us';
      }
      case CONTACT_FORM_TYPE.COFOUND: {
        return 'Co-Found with us';
      }
      case CONTACT_FORM_TYPE.COLLABORATE: {
        return 'Work with us';
      }
      default: {
        return '';
      }
    }
  }, [type]);

  const text = useMemo(() => {
    switch (type) {
      case CONTACT_FORM_TYPE.INVEST: {
        return "Connected devices will transform healthcare outside of hospitals, enabling us to live more active and dignified lives. Increasing global disease burdens and aging societies require immediate action. Join other investors in accelerating impact by becoming part of the solution.";
      }
      case CONTACT_FORM_TYPE.COFOUND: {
        return "In collaboration with our team of engineering and design experts and our global network of healthcare experts, we bring connected devices to market through regulatory approval. As a co-founder, 10XBeta accelerates your product timeline and helps you deliver patients high quality continuous care, optimized to their unique needs.";
      }
      case CONTACT_FORM_TYPE.COLLABORATE: {
        return "With our elite award winning in-house team and global network of domain experts, 10XBeta leverages decades of design and development expertise to accelerate the framing of problems and validation of solutions to create tomorrow's products and experiences.";
      }
      default: {
        return '';
      }
    }
  }, [type]);

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();

    const { name, email, message, file: { files } } = e.target.elements;

    const values: any = {
      interest: type,
      from: email.value,
      subject: getSubject(name.value),
      text: message.value,
    }

    if (files.length) {
      const file = files[0];
      const { name } = file;

      values.attachments = [{
        name,
        base64: await convertFileToBase64(file)
      }];
    }

    setSendState(SendState.SENDING);

    sendGrid(values)
      .then(() => setSendState(SendState.SENT))
      .catch(() => setSendState(SendState.FAILED));
  };

  const getSubject = (name: string) => {
    let subject = 'New ';

    switch (type) {
      case CONTACT_FORM_TYPE.COLLABORATE: {
        subject += 'Collaborator';
        break;
      }
      case CONTACT_FORM_TYPE.INVEST: {
        subject += 'Investor';
        break;
      }
      case CONTACT_FORM_TYPE.COFOUND:
       {
        subject += 'Co-Founder';
        break;
      }
    }

    subject += ` | ${name}`;

    return subject;
  }

  const fileTooLarge = file && file.size > MAX_FILE_SIZE;

  return (
    <StyledContactForm className={`${type?.toLowerCase()}`} onClick={e => e.stopPropagation()}>
      <div className="header">
        <button className="close" onClick={onClose}>
          <Close />
        </button>

        {
          sendState !== SendState.SENT && (
            <div className="h2">{ label }</div>
          )
        }

        {
          sendState === SendState.SENT && (
            <div className="sent-title">
              <div className="h2">
                <div>Thank you for reaching out!</div>
              </div>
            </div>
          )
        }
      </div>

      <div ref={scrollRef} className="wrapper overflow-scroll">
        {
          sendState === SendState.SENT && (
            <div className="sent-content">
              <div>We will be in touch shortly.</div>
              <div>See what we are up to @</div>

              <span className="socials">
                <Link href="https://www.instagram.com/10xbeta/">
                  <Instagram className="social" />
                </Link>
                <Link href="https://twitter.com/10XBeta">
                  <Twitter className="social" />
                </Link>
                <Link href="https://www.linkedin.com/company/10xbeta">
                  <Linkedin className="social" />
                </Link>
              </span>
            </div>
          )
        }

        {
          sendState !== SendState.SENT && (
            <div className="content">
              <div className="text">{ text }</div>

              <div className="form">
                <form onSubmit={handleOnSubmit}>
                  <input name="name" type="text" placeholder="Name" maxLength={FormValidation.MAX_SHORT} required />
                  <input name="email" type="email" placeholder="Email" maxLength={FormValidation.MAX_SHORT} required />
                  <div className="message">
                    <textarea
                      name="message"
                      placeholder="Message"
                      maxLength={FormValidation.MAX_LONG}
                      required
                      value={message}
                      onChange={({target: { value }}: any) => setMessage(value)}
                    />
                    <div className="counter">{message.length}/{FormValidation.MAX_LONG}</div>
                  </div>
                  <div className={`file-upload ${fileTooLarge && 'error'}`}>
                    <label className={`selector ${fileTooLarge || !file ? 'icon' : ''}`}>
                      <input
                        ref={fileInputRef}
                        name="file"
                        type="file"
                        accept="application/pdf"
                        onChange={({ target: { files } }: any) => setFile(files.length ? files[0] : null)}
                      />
                      {
                        file && (
                          <button type="button" className="remove-attachment" onClick={(e) => {
                            e.preventDefault();

                            if (fileInputRef.current) {
                              fileInputRef.current.value = '';
                              setFile(undefined);
                            }
                          }}>
                            <Close />
                          </button>
                        )
                      }
                      <div className="label single-line-ellipsis">
                        { file?.name || 'Attach PDF' }
                      </div>
                      <div className='counter'>
                        { fileTooLarge
                          ? (
                              <>
                                <Error />
                                <div>Too Large</div>
                              </>
                            )
                          : !file

                            ? (
                                <>
                                  <Attachment />
                                  <div>Max. 4mb</div>
                                </>
                              )
                            : (
                                <></>
                              )
                        }
                      </div>
                    </label>
                  </div>

                  {
                    sendState === SendState.FAILED && (
                      <div className="counter error">
                        Something went wrong.
                      </div>
                    )
                  }

                  <button className="submit" type="submit" disabled={fileTooLarge || sendState === SendState.SENDING}>
                    {sendState === SendState.SENDING ? 'Sending...' : 'Send'}
                  </button>
                </form>
              </div>
            </div>
          )
        }

      </div>
    </StyledContactForm>
  );
};
